import { Link as RLink } from "react-router-dom"
import styled, { css } from "styled-components"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

export const Portal = styled.span`
  font-family: ${fonts.bolder};
  padding-left: 6px;
  font-size: 26px;
`

export const MenuIcon = styled.span`
  font-size: 26px;
  padding-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.GREY_DARK};
`

export const LogoTextContainer = styled.div`
  font-size: 20px;
  padding-left: 16px;
  display: flex;
  font-family: ${fonts.bold};
`

export const LogoTextPart1 = styled.p`
  color: ${COLORS.BLACK};
`

export const LogoTextPart2 = styled.p`
  padding-left: 6px;
  color: ${COLORS.PRIMARY};
`

type ContainerProps = {
  h: number
}

export const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 50;
  justify-content: center;
  height: ${(props: ContainerProps) => props.h}px;

  background-color: white;
  border-style: solid;
  border-color: #e7ebf0;
  border-width: 0;
  border-bottom-width: thin;
`

type LinkProps = {
  selected: boolean
}

export const linkCss = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 6px 24px;
  margin: 0 6px;
  transition-duration: 0s;
  font-size: 14px;
  color: ${COLORS.GREY_DARK};

  &:hover {
    font-weight: bolder;
    color: ${COLORS.PRIMARY};
  }

  &:hover svg {
    fill: ${COLORS.PRIMARY};
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Link = styled(RLink)`
  ${linkCss}
  ${(props: LinkProps) =>
    props.selected ? `font-weight: bolder;color: ${COLORS.PRIMARY};border-bottom: 2px solid ${COLORS.PRIMARY};` : "margin-bottom: 2px;"}

  & svg {
    transition-duration: 0s;
    ${(props: LinkProps) => (props.selected ? `fill: ${COLORS.PRIMARY};` : undefined)}
  }
`

export const LinkButton = styled.div`
  ${linkCss}
  cursor: pointer;
  ${(props: LinkProps) =>
    props.selected ? `font-weight: bolder;color: ${COLORS.PRIMARY};border-bottom: 2px solid ${COLORS.PRIMARY};` : "margin-bottom: 2px;"}

  & svg {
    transition-duration: 0s;
    ${(props: LinkProps) => (props.selected ? `fill: ${COLORS.PRIMARY};` : undefined)}
  }
`
