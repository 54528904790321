import { ERRORS, ERRORS_META } from "types/enums.types"

const errors = {
  [`ERROR.${ERRORS_META.DEFAULT_ERROR}`]: "Une erreur est survenue !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.NOT_FOUND}`]: "Le contrat est introuvable !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.DEFAULT_ERROR}`]: "Une erreur est survenue !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.TOO_RECENT}`]: "Le contrat est trop récent !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.NOT_ACTIVE}`]: "Le contrat n'est pas actif !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.SINISTER}`]: "Un sinistre a été signalé sur ce véhicule !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.INVALID_CONTRACT_TYPE}`]: "Le type de contrat est invalide !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.CLIENT_BLOCKED}`]: "Le client est bloqué !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.INVALID_BRAND}`]: "La marque est invalide !",
  [`ERROR.${ERRORS.CONTRACT_ERROR}.${ERRORS_META.INVALID_MODEL}`]: "Le modèle est invalide !",
  [`ERROR.${ERRORS.MUST_CHANGE_PASSWORD}`]: "Votre mot de passe a expiré, vous allez recevoir un mail pour le réinitialiser",
  [`ERROR.${ERRORS.INVALID_MODEL}`]: "Le modèle est invalide !",
  [`ERROR.${ERRORS.INVALID_BRAND}`]: "La marque est invalide !",
  [`ERROR.${ERRORS.CLIENT_BLOCKED}`]: "Le client est bloqué !",
  [`ERROR.${ERRORS.RESTIT_IN_PROGRESS}`]: "La restituion est en cours !",
  [`ERROR.${ERRORS.UNKNOWN_ACCOUNT}`]: "La concession est introuvable !",
  [`ERROR.${ERRORS.OLD_PASSWORD}`]: "Le mot de passe est identique au précédent !",
  [`ERROR.${ERRORS.UNKNOWN_USER}`]: "L'utilisateur est introuvable !",
  [`ERROR.${ERRORS.NO_AGENDA}`]: "L'évènement n'est pas éditable !",
  [`ERROR.${ERRORS.NOT_FOUND}`]: "L'élément est introuvable !",
  [`ERROR.${ERRORS.KEY_NOT_FOUND}`]: "Erreur, la clé de réinitialisation est incorrecte !",
  [`ERROR.${ERRORS.INVALID_CREDENTIALS}`]: "Erreur, identifiants incorrectes !",
  [`ERROR.${ERRORS.RESTIT_ALREADY_TODO}`]: "L'évènement est déjà programmé le {date} !",
  [`ERROR.${ERRORS.RESTIT_ALREADY_DONE}`]: "La restitution est déjà pré-clôturée !",
  [`ERROR.${ERRORS.VEHICLE_PLEDGED}`]: "Le véhicule est gagé !",
}

export default errors
