import React, { useState } from "react"

import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded"
import TodayIcon from "@mui/icons-material/Today"
import { Box, Menu, MenuItem } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"

import Logo from "components/logo"

import * as S from "./Header.styles"

type Props = {
  height?: number
}

const Header = ({ height = 50 }: Props) => {
  const intl = useIntl()
  const location = useLocation()

  const [selected, setSelected] = useState(location.pathname)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const restitutionsUrl = intl.formatMessage({ id: "link.restitutions" })
  const planning = intl.formatMessage({ id: "link.planning" })
  const accountUrl = intl.formatMessage({ id: "link.account" })

  return (
    <S.MainContainer h={height}>
      <Box p={2} justifyContent="space-between" display="flex" maxWidth={1650} width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Logo />
          <S.LogoTextContainer>
            <S.LogoTextPart1>
              <FormattedMessage id="header.logo.part1" />
            </S.LogoTextPart1>
            <S.LogoTextPart2>
              <FormattedMessage id="header.logo.part2" />
            </S.LogoTextPart2>
          </S.LogoTextContainer>
        </Box>
        <Box display="flex" alignItems="center">
          <S.Link to={restitutionsUrl} onClick={() => setSelected(restitutionsUrl)} selected={selected === restitutionsUrl}>
            <S.MenuIcon>
              <ListAltRoundedIcon fontSize="inherit" color="secondary" />
            </S.MenuIcon>
            <FormattedMessage id={"link.label.restitutions"} />
          </S.Link>
          <S.Link to={planning} onClick={() => setSelected(planning)} selected={selected === planning}>
            <S.MenuIcon>
              <TodayIcon fontSize="inherit" color="secondary" />
            </S.MenuIcon>
            <FormattedMessage id={"link.label.planning"} />
          </S.Link>
          <S.LinkButton onClick={handleClick} selected={selected === accountUrl}>
            <S.MenuIcon>
              <AccountCircleIcon fontSize="inherit" color="inherit" />
            </S.MenuIcon>
            <FormattedMessage id={"link.label.account"} />
          </S.LinkButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                window.dispatchEvent(new CustomEvent("resetAuth"))
              }}
            >
              <FormattedMessage id={`header.menu.logout`} />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </S.MainContainer>
  )
}

export default Header
