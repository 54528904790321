const agenda = {
  "screen.agenda.title": "Créer un rendez-vous",
  "screen.agenda.create.event": "Pour créer un rendez-vous, veuillez renseigner l'immatriculation du véhicule à restituer",
  "screen.agenda.create.success": "L'événement a été créé avec succés !",
  "screen.agenda.delete.success": "L'événement a été supprimé avec succés !",
  "screen.agenda.search.error": "Ce véhicule ne peut pas être restitué",
  "screen.agenda.search.success": "Ce véhicule peut être restitué",
  "screen.agenda.section.vehicle": "Véhicule",
  "screen.agenda.section.tenant": "Locataire",
  "screen.agenda.button.dekra": "Missionner un expert DEKRA",
  "screen.agenda.or": "OU",
  "screen.agenda.restitution.form": "Je procède moi-même à la restitution",
  "screen.agenda.noResults": "Aucun résultat",
  "screen.agenda.inProgress.title": "Restitution déjà en cours",
  "screen.agenda.inProgress.body": "La restitution est en cours, voulez-vous créer un nouveau rdv ?",
  "screen.agenda.inProgress.confirm": "Oui",
  "screen.agenda.inProgress.cancel": "Non",
  "screen.agenda.pledgedVehicle.warning": "Attention, ce véhicule est actuellement signalé gagé !",
  "screen.agenda.pledgedVehicle.body1": "Aucune restitution ne doit être engagée et sous aucun prétexte.",
  "screen.agenda.pledgedVehicle.body2": "Elle ne pourra avoir lieu qu'une fois le gage levé.",
  "screen.agenda.pledgedVehicle.body3": "Le client peut contacter l'équipe fin de contrat au 08 00 10 05 57",
  "screen.agenda.pledgedVehicle.cancel": "Annuler",
}

export default agenda
