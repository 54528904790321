import React from "react"

import { FormattedMessage } from "react-intl"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { Term, TermFilter } from "types/common.types"
import { CONTRACT_TYPE } from 'types/enums.types';
import { isAnyTermChecked } from "utils/search.utils"

import * as S from "./Filter.style"

type Props = {
  filter: TermFilter
  name: string
}

type CommonToggleType = {
  common: CONTRACT_TYPE
  term: CONTRACT_TYPE
}

const compare = (a: string, b: string) => {
  if (a > b) {
    return 1
  }
  if (b > a) {
    return -1
  }
  return 0
}

const commonToggle = [{
  term: CONTRACT_TYPE.L11,
  common: CONTRACT_TYPE.L10
}, {
  term: CONTRACT_TYPE.L25,
  common: CONTRACT_TYPE.L20
}]

const shouldDisplayTerm = (term: string, terms: Term[], commonToggle: CommonToggleType[]) => {
  const commonTerm = commonToggle.find(ct => ct.term === term);
  if (commonTerm) {
    return !terms.some(t => t.term === commonTerm.common);
  }
  return true;
};

const customTranslation = (term: string) => {
  if(term === CONTRACT_TYPE.L20 || term === CONTRACT_TYPE.L25) {
    return 'L20_25'
  }
  else return term
}

const getAssociatedTerms = (term: string, commonToggle: CommonToggleType[]) => {
  const commonTerm = commonToggle.find(ct => ct.term === term || ct.common === term);
  if (commonTerm) {
    return [commonTerm.term, commonTerm.common];
  }
  return [term];
};


const ToggleFilter = ({ filter, name }: Props) => {
  const dispatch = useReduxDispatch()

  if (!filter || !filter.terms) return <div/>

  const { terms } = filter

  const anySelected = isAnyTermChecked(filter)

  const handleToggleClick = (term: string) => {
    const associatedTerms = getAssociatedTerms(term, commonToggle);
    if (associatedTerms.length > 1) {
      dispatch(actions.search.checkMultipleTerms({ filter: name, names: associatedTerms, reset: true }));
    } else {
      dispatch(actions.search.checkTerm({ filter: name, name: term, reset: true }));
    }
  };

  return (
    <S.Container>
      <S.Toggle enabled={ !anySelected } onClick={ () => dispatch(actions.search.resetFilter(name)) }>
        <FormattedMessage id="button.toggle.all"/>
      </S.Toggle>
      { terms &&
        [...terms]
          .sort((a, b) => compare(a.term, b.term))
          .filter(t => shouldDisplayTerm(t.term, terms, commonToggle))
          .map((t) => (
            <S.Toggle
              key={ t.term }
              enabled={ t.checked }
              onClick={ () => handleToggleClick(t.term) }
            >
              <FormattedMessage id={ `restitution.contractType.${ customTranslation(t.term) }` }/>
            </S.Toggle>
          )) }
    </S.Container>
  )
}

export default ToggleFilter
